@use "../config" as *;
@each $name,
$color in $social-colors {
    .social-link-#{$name} {
        .social-anchor {
            &::before {
                color: hsl($color);
            }
        }
    }
}
.waf-row-team-masthead {
    z-index: var(--z-masthead);
    @extend %relative;
    div[class*=col-lg] {
        @extend %p-0;
    }
    .site,
    .contact {
        @extend %black-bg-2;
    }
    .waf {
        &-team {
            @extend %p-0;
            @extend %mb-0;
        }
        &-head {
            @extend %d-none;
        }
    }
    .card {
        &-image {
            .image {
                aspect-ratio: 16/9;
                @extend %w-100;
                @extend %h-100;
            }
        }
        &-thumbnail {
            @extend %relative;
            .image {
                object-fit: cover;
            }
        }
        &-logo {
            width: var(--club-size);
            height: var(--club-size);
            aspect-ratio: 1/1;
            bottom: calc(var(--club-size)/2 * -1);
            outline: 1rem solid hsl(var(--hsl-white)/0.64);
            z-index: var(--z-club-logo);
            @extend %hidden;
            @extend %rounded-radius;
            @extend %position-x-center;
            @extend %white-bg;
            @extend %p-2;
        }
        &-content {
            padding-block: calc(var(--club-size)/2 + 1rem);
            @extend %relative;
            @extend %relative;
            @extend %hidden;
            &::after,
            &::before {
                content: "";
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                opacity: .15;
                width: 26rem;
                height: 26rem;
                pointer-events: none;
                filter: invert(1);
                @include bgImg("svg/hexagon.svg");
            }
            &::before {
                top: -10rem;
                left: -12rem;
            }
            &:after {
                right: -18rem;
                bottom: -5rem;
            }
            &-list {
                max-width: 28rem;
                margin: 0 auto;
            }
            &-title {
                @extend %font-35-pb;
                @extend %uppercase;
                @extend %white;
                @extend %text-center;
            }
            &-item {
                border-radius: .25rem;
                padding: var(--space-1) var(--space-2);
                @extend %my-2;
                @extend %text-left;
                a {
                    @extend %d-block;
                }
                .text,
                .label,
                a {
                    @extend %font-12-pm;
                    @extend %white;
                }
                .label {
                    @extend %d-none;
                }
                .text {
                    @extend %relative;
                    @extend %flex;
                    @extend %gap-1;
                    &::before {
                        pointer-events: none;
                        content: "□";
                        font: 1.8rem $font-icon;
                        @extend %pr-1;
                    }
                }
            }
            .venue {
                .text {
                    padding-inline-start: var(--space-6);
                    &:before {
                        position: absolute;
                        left: 0;
                        @include icon(stadium);
                    }
                }
            }
            .site {
                .text:before {
                    position: absolute;
                    right: 0;
                    @include icon(arrow-right);
                }
            }
            .contact {
                .text:before {
                    @include icon(email);
                }
            }
            .social {
                @extend %mt-4;
                .title {
                    @extend %d-none;
                }
                &-text {
                    @extend %d-none;
                }
                &-anchor {
                    width: 3rem;
                    height: 3rem;
                    @extend %flex-c-c;
                    @extend %rounded-radius;
                    @extend %white-bg;
                    &::before {
                        font: 1.8rem $font-icon;
                    }
                }
                &-list {
                    list-style-type: none;
                    @extend %gap-2;
                    @extend %p-0;
                    @extend %flex-c-c;
                }
            }
        }
    }
}
@media (min-width: $large-desktop-min-width) {
    .waf-row-team-masthead {
        .card {
            &-wrapper {
                display: flex;
            }
            &-thumbnail,
            &-content {
                width: 50%;
            }
            &-image {
                height: 100%;
                aspect-ratio: 16/9;
            }
            &-content {
                padding-left: 12rem;
                display: flex;
                justify-content: center;
                flex-direction: column;
                padding-block: 0;
                &::before {
                    top: -18rem;
                    left: unset;
                }
                &:after {
                    right: -70%;
                    bottom: unset;
                    width: 100%;
                    height: 100%;
                }
                &-title {
                    text-align: left;
                    font-size: 3.4rem;
                    margin-bottom: 0;
                }
                &-list {
                    margin: 0;
                }
                .social {
                    padding-inline-start: 0;
                    &-list {
                        justify-content: flex-start;
                    }
                }
            }
            &-logo {
                inset-block: 0;
                margin-block: auto;
                right: calc(var(--club-size)/2 * -1);
                left: auto;
                padding: var(--space-5);
                translate: unset;
            }
        }
    }
}
@media (min-width: $tablet-min-width) {
    .waf-row-team-masthead {
        .layout-wrapper {
            max-width: 100%;
        }
    }
}