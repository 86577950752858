@use "../config" as *;
.sticky {
    &.club-page {
        --header-height: var(--secondary-header-height);
        &.webview {
            --header-height: 0rem;
        }
    }
    .secondary-header {
        @extend %w-100;
        @extend %px-0;
        @include position(0, null, null, 0, fixed);
        .sub-ul {
            width: var(--content-width);
        }
    }
    .header-wrap {
        display: none;
    }
}
.secondary-header {
    z-index: var(--z-secondary-header);
    width: var(--container-max-width);
    top: calc((var(--header-height) + ((9/16)*var(--window-inner-width) + 40.8rem)) - var(--secondary-header-height));
    // top: calc((var(--header-height) + (((9/16)*var(--window-inner-width)) + 24.8rem)) - var(--secondary-header-height));
    height: var(--secondary-header-height);
    transition: all .4s ease;
    @extend %mx-auto;
    @extend %px-0;
    @include position(null, 0, null, 0);
    .club-logo {
        width: 6rem;
        height: 6rem;
        @extend %p-1;
        @extend %mt-1;
        @extend %circle-radius;
        @extend %white-bg;
        @extend %d-none;
        .logo {
            border-radius: 0;
            box-shadow: unset;
            background-size: contain;
            @extend %h-100;
            @extend %w-100;
            @extend %transparent-bg;
            @extend %ml-0;
        }
    }
    .site-nav {
        @extend %relative;
    }
    .nav {
        &-link-list {
            list-style: none;
            height: var(--secondary-header-height);
            @extend %flex;
            @extend %px-0;
        }
        &-link {
            max-width: 15rem;
            flex: 1;
            padding: 0 var(--space-1);
            @extend %relative;
            @extend %flex-c-c;
            @extend %secondary-dark-bg;
            &:not(:last-child):after {
                content: '';
                right: 0;
                width: .1rem;
                @extend %h-60;
                @extend %white-bg-5;
                @extend %position-y-center;
            }
            &.active {
                .nav-text {
                    font-weight: bold;
                }
            }
        }
        &-anchor {
            @extend %h-100;
            @extend %w-100;
            @extend %px-0;
            @extend %flex-c-c;
            &::before {
                content: unset;
            }
            .btn-text {
                @extend %capitalize;
                @extend %font-14-pr;
                @extend %white;
            }
        }
        &-text {
            line-height: 1.2;
            @extend %text-center;
            @extend %capitalize;
            @extend %font-14-pr;
            @extend %white;
        }
    }
    button.nav-anchor {
        position: relative;
        &::after {
            line-height: 1;
            @extend %transition;
            @extend %white;
            @extend %px-2;
            @include icon(chevron-down, 14);
        }
    }
    .sub {
        &-nav-link {
            height: var(--secondary-header-height);
            @extend %px-4;
            @extend %relative;
            @extend %flex-c-c;
            &:not(:last-child):after {
                content: '';
                height: .1rem;
                left: auto;
                @extend %w-90;
                @extend %secondary-bg-3;
                @extend %position-b-l;
            }
            &.active {
                @extend %white-bg;
                @extend %club-primary;
                .nav-anchor {
                    .nav-text {
                        @extend %club-secondary;
                    }
                }
            }
        }
        &-ul {
            list-style: none;
            width: calc(var(--content-width));
            -webkit-backdrop-filter: blur(2rem);
            backdrop-filter: blur(2rem);
            @extend %white-bg-5;
            @extend %pl-0;
            @include dropdown(close);
            @include position(var(--secondary-header-height), 0, null, auto);
        }
    }
    .sub-ul {
        .nav {
            &-text {
                @extend %capitalize;
                @extend %font-12-pm;
                @extend %secondary-light;
            }
            &-anchor {
                justify-content: flex-start;
                @extend %py-3;
            }
        }
    }
    [aria-expanded="true"] {
        &::after {
            transform: rotate(180deg);
        }
        ~ .sub-ul {
            @include dropdown(open);
        }
    }
}
@each $clubid ,
$clubval in $club-colors {
    .club-#{$clubid} {
        .secondary-header {
            .club-logo {
                .logo {
                    @include bgImg("club/#{$clubid}.png")
                }
            }
        }
    }
}
@media (min-width:$tablet-min-width) {
    .sticky {
        .header-wrap {
            display: none;
        }
        .secondary-header {
            height: var(--secondary-header-height);
            width: var(--content-width);
            display: flex;
            padding-inline: var(--container-white-space);
            top: 0;
            .sub-ul {
                width: 100%;
            }
        }
        .club-logo {
            display: block;
        }
        .site-nav {
            width: 100%;
            padding-left: var(--space-8);
        }
    }
    .secondary-header {
        padding-inline: 0;
        width: var(--container-max-width);
        .nav-link-list {
            height: var(--secondary-header-height);
        }
        .sub-ul {
            top: var(--secondary-header-height);
            width: 100%;
        }
    }
}
@media (min-width:$large-desktop-min-width) {
    .secondary-header {
        top: calc(var(--header-height) + ((9/16)*(var(--window-inner-width) / 2)) - var(--secondary-header-height));
    }
}