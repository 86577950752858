@use "../config" as *;

.waf-video-inner-listing{
    .first-list{
        @include listing-card(overlay);
        @extend %mb-3;
    }
    .second-list{
        @extend %rhs-card-list;
    }
}

@media screen and (min-width: $tablet-min-width){
    .waf-video-inner-listing{
        .article{
            &-list{
                @include card-count(4, var(--space-3), wrap);
                @include listing-card(card);
                .article-content{
                    bottom: 0;
                    a{
                        color: var(--secondary-light);
                    }
                    .article-meta{
                        .meta{
                            color: hsl(var(--hsl-secondary-light) / 0.8);
                        }
                    }
                }
            }
        }
        .item-type-video{
            .item-type-icon{
                &::before{
                    font-size: 2.5rem;
                }
            }
        }
    }
}

@media screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width){
    .waf-video-inner-listing{
        .article{
            &-list{
                @include card-count(3, var(--space-3), wrap);
            }
        }
    }
}